var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "container",
      staticClass: "field",
      class: {
        "field--has-focus": _vm.focus,
        "field--error": _vm.errors.length && _vm.errors[0].type === "error",
        "field--thin": !_vm.caption && !_vm.tall,
      },
      on: {
        focusin: function ($event) {
          return _vm._focusin()
        },
        focusout: function ($event) {
          return _vm._focusout()
        },
      },
    },
    [
      _c("label", { staticClass: "field__label", attrs: { for: _vm._id } }, [
        _vm._v("\n    " + _vm._s(_vm.label) + "\n  "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "field__input-wrapper" }, [
        _c(
          "div",
          {
            ref: "innerWrapper",
            staticClass: "field__input-wrapper-inner",
            class: { "field__input-wrapper-inner--with-icon": _vm.icon },
            on: {
              click: function ($event) {
                return _vm._innerWrapperClick()
              },
              keydown: function ($event) {
                return _vm._keydown($event)
              },
            },
          },
          [
            _vm._l(_vm.value, function (tag, index) {
              return _vm.tags
                ? _c(
                    "div",
                    {
                      ref: "tags",
                      refInFor: true,
                      staticClass: "field__tag",
                      attrs: { tabindex: "-1", title: tag },
                      on: {
                        click: function ($event) {
                          return _vm._tagClick(index)
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "field__tag-text" }, [
                        _vm._v(_vm._s(tag)),
                      ]),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "field__tag-icon",
                        domProps: { innerHTML: _vm._s(_vm.tagIcon) },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.removeTag(index)
                          },
                          mousedown: function ($event) {
                            $event.preventDefault()
                            return (function () {})($event)
                          },
                        },
                      }),
                    ]
                  )
                : _vm._e()
            }),
            _vm._v(" "),
            _c("input", {
              ref: "input",
              staticClass: "field__input",
              attrs: {
                id: _vm._id,
                name: _vm.name,
                type: _vm.type,
                autocomplete: _vm.autocomplete,
                size: "1",
              },
              domProps: { value: _vm._value },
              on: {
                input: function ($event) {
                  return _vm.update($event.target.value)
                },
                focus: function ($event) {
                  return _vm._focus()
                },
                blur: function ($event) {
                  return _vm._blur()
                },
                change: function ($event) {
                  return _vm.change()
                },
              },
            }),
          ],
          2
        ),
        _vm._v(" "),
        _vm.icon
          ? _c("span", {
              staticClass: "field__icon",
              class: {
                "field__icon--small": _vm.icon.iconSmall,
                "field__icon--fixed": _vm.icon.iconFixed,
                "field__icon--fixed-toggled": _vm.icon.iconToggled,
              },
              attrs: { title: _vm.icon.iconTitle },
              domProps: { innerHTML: _vm._s(_vm.icon.iconSVG) },
              on: {
                click: function ($event) {
                  return _vm.$emit("iconClick")
                },
              },
            })
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm._caption
        ? _c("span", { staticClass: "field__caption" }, [
            _vm._v("\n    " + _vm._s(_vm.captionText) + "\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._t("dropdown"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }