// Proudly stolen from https://css-tricks.com/snippets/javascript/move-cursor-to-end-of-input/
export function moveCursorToEnd(el) {
  if (typeof el.selectionStart == 'number') {
    el.selectionStart = el.selectionEnd = el.value.length;
  } else if (typeof el.createTextRange != 'undefined') {
    el.focus();
    var range = el.createTextRange();
    range.collapse(false);
    range.select();
  }
}

// Based on https://ourcodeworld.com/articles/read/282/how-to-get-the-current-cursor-position-and-selection-within-a-text-input-or-textarea-in-javascript
// Doubt if it works for textareas
export function cursorPosition(el) {
  if (typeof el.selectionStart == 'number') {
    if (el.selectionStart === el.selectionEnd) {
      return el.selectionStart;
    }
  } else {
    return { start: el.selectionStart, end: el.selectionEnd };
  }
}
