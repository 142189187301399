import error from 'apps/public/img/baseline-error-24px.svg';

import compact from 'lodash/compact';

export function addError(error) {
  this.errors.push(errorFormatter(error));
}

export function errorFormatter(err) {
  if (!err.message) err = { message: err };

  if (!err.type) err.type = 'error';
  if (!err.iconSVG) err.iconSVG = error;
  if (!err.iconTitle) err.iconTitle = 'Error!';

  return err;
}

export function resetValidation() {
  this.validated = false;
  this.validate().catch(() => {}); // Ignore errors
}

export async function validate() {
  const done = (result) => {
    this.validated = true;

    return result;
  };

  if (!this.validation) return done(true);

  try {
    // Ensure search data is in place
    await this.validationPreload;

    // Handle password autocomplete hiding the actual value
    if (typeof this.value === 'undefined' && this._type === 'password') {
      // Skip client side validation, since we can't access the value
      this.errors = [];
      return true;
    }

    const validationResults = await Promise.all(
      (Array.isArray(this.validation) ? this.validation : [this.validation]).map((v) => {
        let res = v(this.value, this);

        // Async validation
        if (res && res.then) return res;

        // Sync validation
        return Promise.resolve(res);
      })
    );

    this.errors = compact(validationResults).map(errorFormatter);

    done(!this.errors.length);
  } catch (e) {
    // Skip client side if unknown error happen when validationPreload'ing
    // Skip client side validation if unknown errors happen
    this.errors = [];
    return true;
  }
}

export async function validates() {
  if (!this.validated) return await this.validate();

  return !this.errors.length;
}

export function validationData() {
  return {
    errors: [],
    validationPreload: Promise.resolve(),
    validated: false,
  };
}

export const validationProps = {
  validation: {},
};
