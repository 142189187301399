<template>
  <div
    class="field"
    :class="{
      'field--has-focus': focus,
      'field--error': errors.length && errors[0].type === 'error',
      'field--thin': !caption && !tall,
    }"
    ref="container"
    @focusin="_focusin()"
    @focusout="_focusout()"
  >
    <label class="field__label" :for="_id">
      {{ label }}
    </label>
    <div class="field__input-wrapper">
      <div
        class="field__input-wrapper-inner"
        :class="{ 'field__input-wrapper-inner--with-icon': icon }"
        ref="innerWrapper"
        @click="_innerWrapperClick()"
        @keydown="_keydown($event)"
      >
        <div
          class="field__tag"
          tabindex="-1"
          v-if="tags"
          v-for="(tag, index) in value"
          :title="tag"
          ref="tags"
          @click="_tagClick(index)"
        >
          <span class="field__tag-text">{{ tag }}</span>
          <!-- mousedown.prevent prevents focus from happening.
            We want to prevent that whenever a click happens
            when nothing was in focus, since it's a little bit
            odd to get the input in focus when that happens. -->
          <span
            class="field__tag-icon"
            v-html="tagIcon"
            @click.stop="removeTag(index)"
            @mousedown.prevent="() => {}"
          ></span>
        </div>
        <input
          class="field__input"
          :id="_id"
          :name="name"
          :value="_value"
          :type="type"
          :autocomplete="autocomplete"
          @input="update($event.target.value)"
          @focus="_focus()"
          @blur="_blur()"
          @change="change()"
          size="1"
          ref="input"
        />
      </div>
      <span
        class="field__icon"
        v-if="icon"
        :class="{
          'field__icon--small': icon.iconSmall,
          'field__icon--fixed': icon.iconFixed,
          'field__icon--fixed-toggled': icon.iconToggled,
        }"
        :title="icon.iconTitle"
        v-html="icon.iconSVG"
        @click="$emit('iconClick')"
      ></span>
    </div>
    <span v-if="_caption" class="field__caption">
      {{ captionText }}
    </span>
    <slot name="dropdown"></slot>
  </div>
</template>

<script>
import close from '../../img/baseline-close-24px.svg';
import { v4 as uuidv4 } from 'uuid';

import { cursorPosition, moveCursorToEnd } from 'apps/public/lib/cursor-helpers';

export default {
  name: 'BaseField',
  props: {
    autocomplete: {
      default: false,
    },
    caption: {},
    errors: {
      type: Array,
      default: () => [],
    },
    icon: {},
    id: {},
    label: {},
    name: {},
    tall: {},
    type: {},
    value: {},
  },
  data() {
    return {
      focus: false,
      focusin: false,
      tagIcon: close,
      tagFieldValue: '',
    };
  },
  computed: {
    _id() {
      return this.id || (this.name || '') + '-' + uuidv4();
    },
    _caption() {
      return (this.caption || this.errors.length) && !!this.captionText;
    },
    captionText() {
      return this.errors.length ? this.errors[0].message : this.caption;
    },
    tags() {
      return Array.isArray(this.value);
    },
    _value() {
      if (this.tags) {
        return this.tagFieldValue;
      } else {
        return this.value;
      }
    },
  },
  methods: {
    iconClick() {
      this.clear();
    },
    clear() {
      this.update('');
    },
    reset() {
      this.clear();
      this.$nextTick(() => {
        this.blur();
      });
    },
    update(value, context) {
      if (this.tags) {
        this.tagFieldValue = value;
        this.$emit('fieldValue', value, context);
      } else {
        this.$emit('input', value, context);
      }
    },
    change() {
      // Fixes issue with HMR where it seems $refs are not kept up-to-date at all times
      if (this.$refs.input) {
        // Fix auto complete issues - see https://github.com/vuejs/vue/issues/7058
        if (this.tags) {
          this.update(this.$refs.input.value);
        } else {
          this.value = this.$refs.input.value;
          this.update(this.$refs.input.value);
        }
      }

      if (this.$refs.input) {
        if (this.tags) {
          let val = this.value;
          const fieldValue = this.tagFieldValue.trim();

          val = val.concat([fieldValue]);
          this.tagFieldValue = '';

          this.$emit('input', val);
        }

        this.$emit('change', this.value);
      }
    },
    removeTag(index) {
      let newArr = [...this.value];

      newArr.splice(index, 1);

      this.$emit('input', newArr);

      // If nothing was selected before, don't select anything
      if (this.$refs.tags[index] === document.activeElement) {
        if (this.tags.length) {
          this.selectTag(index - 1);
        } else {
          // No tags left to select - put focus in input instead (put cursor in input)
          this.setFocus();
        }
      }
    },
    selectTag(index) {
      if (index < 0) index = 0;

      if (index >= this.value.length) {
        // Put cursor in input field
        this.$refs.input.focus();
      } else {
        // Select tag
        this.$refs.tags[index].focus();

        // Check if in view, otherwise, scroll to make it in view
        const innerWrapperRect = this.$refs.innerWrapper.getBoundingClientRect();
        const tagRect = this.$refs.tags[index].getBoundingClientRect();

        // Check if not visible on the right, then scroll to fit it on the right
        if (innerWrapperRect.x + innerWrapperRect.width < tagRect.x + tagRect.width) {
          this.$refs.innerWrapper.scrollLeft +=
            tagRect.x + tagRect.width - (innerWrapperRect.x + innerWrapperRect.width);
        }

        // Check if not visible on the left, then scroll to fit it on the left
        if (tagRect.x < innerWrapperRect.x) {
          this.$refs.innerWrapper.scrollLeft -= innerWrapperRect.x - tagRect.x;
        }
      }
    },
    setFocus() {
      this.$refs.input.focus();
      moveCursorToEnd(this.$refs.input);
    },
    _focus() {
      this.focus = true;
      this.$emit('focus');
    },
    blur() {
      // No blurring if not in focus
      if (document.activeElement !== this.$refs.input) return;

      this.$refs.input.blur();
    },
    _blur() {
      this.focus = false;
      this.$emit('blur');
    },
    _tagClick(index) {
      this.selectTag(index);
    },
    _keydown($event) {
      if (this.tags) {
        const { keyCode } = $event;
        const selectedTag = this.value.length ? this.$refs.tags.findIndex(($el) => document.activeElement === $el) : -1;

        if (selectedTag >= 0) {
          // Left arrow
          if (keyCode === 37) {
            this.selectTag(selectedTag - 1);
          }

          // Right arrow
          if (keyCode === 39) {
            this.selectTag(selectedTag + 1);
          }

          // Backspace, delete
          if (keyCode === 8 || keyCode === 46) {
            this.removeTag(selectedTag);
          }

          // Left arrow, right arrow, backspace, delete
          if ([8, 37, 39, 46].includes(keyCode)) {
            $event.preventDefault();
            return;
          }
        }

        if (selectedTag < 0 && cursorPosition(this.$refs.input) === 0) {
          // Left arrow or backspace
          if (keyCode === 37 || keyCode === 8) {
            $event.preventDefault();

            this.selectTag(this.value.length - 1);
            return;
          }
        }

        // Enter or tab
        if ([9, 13].includes(keyCode) && selectedTag < 0 && this.tagFieldValue.trim() !== '') {
          $event.preventDefault();

          this.change();
        }
      }

      this.$emit('keydown', $event);
    },
    _updateInputMinWidth() {
      if (!this.$refs.input) return;

      this.$nextTick(() => {
        // Reset width (in order to also handle size reduction)
        this.$refs.input.style.minWidth = '';

        // Calculate and set a new min width value
        const computedStyles = window.getComputedStyle(this.$refs.input);
        this.$refs.input.style.minWidth =
          this.$refs.input.scrollWidth -
          parseFloat(computedStyles.paddingLeft) -
          parseFloat(computedStyles.paddingRight) +
          'px';
      });
    },
    _focusin() {
      this.$emit('focusin');
      this.focusin = true;
    },
    _focusout() {
      this.$emit('focusout');
      this.focusin = false;

      // Reset scroll to mimic browser behavior
      if (this.tags) {
        this.$nextTick(() => {
          if (!this.focusin) this.$refs.innerWrapper.scrollLeft = 0;
        });
      }
    },
    _innerWrapperClick() {
      if (this.focusin) return;

      this.setFocus();
    },
  },
  watch: {
    tagFieldValue: function () {
      this._updateInputMinWidth();
    },
  },
};
</script>

<style lang="scss">
@use 'sass:math';

// See ~common/styles/field.scss
</style>
